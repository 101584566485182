<template>
    <div class="pill">
        {{ item }}
    </div>
</template>

<script>
export default {
    name: "Pill",
    props: {
        item: {
            type: String
        }
    }
}
</script>
