<template>
  <div class="links-container">
    <a class="social-link" href="https://github.com/jordisz">
      <IconBase iconName="Ves al meu GitHub"><GitHub /></IconBase>
    </a>
    <a
      class="social-link"
      href="https://www.linkedin.com/in/jordi-sanchez-91868225/"
    >
      <IconBase iconName="Ves al meu perfil de LinkedIn"><LinkedIn /></IconBase>
    </a>
    <a class="social-link" href="https://twitter.com/jordi_sz_">
      <IconBase iconName="Ves al meu perfil de Twitter"><Twitter /></IconBase>
    </a>
    <a v-if="envelopeIcon === true" class="social-link" href="#contact">
      <IconBase iconName="Escriu-me un missatge"><EMail /></IconBase>
    </a>
  </div>
  <!-- Icons by IonIcons: https://github.com/ionic-team/ionicons -->
</template>

<script>
import IconBase from "./IconBase.vue";
import GitHub from "./icons/GitHub.vue";
import EMail from "./icons/EMail.vue";
import LinkedIn from "./icons/LinkedIn.vue";
import Twitter from "./icons/Twitter.vue";

export default {
  components: {
    IconBase,
    EMail,
    GitHub,
    LinkedIn,
    Twitter,
  },
  props: {
    envelopeIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
