<template>
    <div class="contact-container" id="contact">
        <div class="contact-flex">
            <div class="contact-left">
                <h2 v-if="sentMsg === false">Envia'm un missatge</h2>
                <h2 v-else>Missatge enviat</h2>
                <ContactForm @enviat="handleSentMsg"/>
            </div>
            <div class="contact-right">
                <h2>O troba'm també a</h2>
                <Links :envelopeIcon="false"/>
            </div>
        </div>
    </div>
</template>

<script>
import ContactForm from "./ContactForm.vue"
import Links from "./Links.vue"

export default {
    name: 'Contact',
    components: {
        ContactForm,
        Links
    },
    data() {
        return {
            sentMsg: false
        }
    },
    methods: {
        handleSentMsg() {
            this.sentMsg = true;
        }
    }
}
</script>
