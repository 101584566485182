<template>
  <div class="about-container">
    <h1 class="main-header">Jordi Sánchez</h1>
    <h2 class="main-subheader">desenvolupador frontend</h2>
    <p class="about-text">
      Interessat en la combinació de <span class="bigger">disseny</span> i <span class="bigger">programació</span> per a aportar
      <span class="bigger">solucions</span> a necessitats comunicatives i de negoci.<br />
      M'encanta adquirir nous <span class="bigger">coneixements</span> i <span class="bigger">contribuir</span> amb les meves habilitats
      als equips de què formo part.
    </p>
    <Links />
  </div>
</template>

<script>
import Links from "@/components/Links.vue";

export default {
  name: "About",
  components: {
    Links,
  },
};
</script>

<style></style>
