<template>
    <div class="card-container">
        <video controls>
            <source :src="require(`../assets/${project.image}`)" type="video/mp4">
        </video>
        <div>
            <h2 class="project-name">{{ project.name }}</h2>
            <p class="year">{{ project.year }}</p>
            <p class="description" v-html="project.description_cat"></p>
            <div class="stack">
                <Pill v-for="(item, index) in project.stack" :key="index" :item="item" />
            </div>
            <div class="categories">
                <Pill v-for="(item, index) in project.categories" :key="index" :item="item" />
            </div>
        </div>
    </div>
</template>

<script>
import Pill from '@/components/Pill.vue';

export default {
    components: {
        Pill
    },
    props: {
        project: {
            type: Object
        }
    }
}
</script>
