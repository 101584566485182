<template>
  <div class="projects-container">
    <ProjectCard v-for="(project, index) in sorted" :key="index" :project="project"/>
    <div v-if="!showAllProjects" class="overlay">
      <button class="show-more" @click="showAllProjects = !showAllProjects">Veure més projectes</button>
    </div>
  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
import Projects from '@/assets/projects.json'

export default {
  name: 'Projects',
  components: {
    ProjectCard
  },
  data() {
    return {
      projects: Projects,
      showAllProjects: false
    }
  },
  computed: {
    sorted() {
      let sortedArray = this.projects.sort((a, b) => b.id - a.id);
      if (this.showAllProjects === true) {
        return sortedArray.slice(0, 10)
      }
      return sortedArray.slice(0, 5)
    }
  }
}
</script>
