<template>
  <About/>
  <Projects/>
  <Contact/>  
</template>

<script>
import About from '@/components/About.vue'
import Projects from '@/components/Projects.vue'
import Contact from '@/components/Contact.vue'

export default {
  name: 'App',
  components: {
    About,
    Projects,
    Contact
  }
}
</script>
