<template>
  <form
    v-if="emailSent === false"
    class="form"
    ref="form"
    @submit.prevent="sendEmail"
  >
    <label>Nom</label>
    <input class="form-input" type="text" name="from_name" v-model="formName" />
    <label>Email</label>
    <input
      class="form-input"
      type="email"
      name="from_email"
      v-model="formEmail"
    />
    <label>Missatge</label>
    <textarea
      class="form-input text-area"
      name="message"
      v-model="formTextArea"
    ></textarea>
    <input
      :disabled="validated === false"
      class="send-button"
      type="submit"
      value="Envia"
    />
  </form>
  <div v-else>Gràcies per contactar, et respondré tan aviat com pugui.</div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  name: "ContactForm",
  data() {
    return {
      emailSent: false,
      formName: "",
      formEmail: "",
      formTextArea: "",
    };
  },
  computed: {
    validated() {
      return (
        this.formName !== "" &&
        this.formEmail !== "" &&
        this.formTextArea !== ""
      );
    },
  },
  methods: {
    sendEmail() {
      const SID = process.env.VUE_APP_EJS_SERVICE_ID;
      const TID = process.env.VUE_APP_EJS_TEMPLATE_ID;
      const UID = process.env.VUE_APP_EJS_USER_ID;
      emailjs.init(UID);
      emailjs.sendForm(SID, TID, this.$refs.form).then(
        (result) => {
          console.log("SUCCESS!", result.text);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
      this.emailSent = true;
      this.$emit("enviat");
    },
  },
};
</script>
